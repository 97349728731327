import React, { useEffect, useState } from "react";
import Breadcrumb from "../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Table,
  CardHeader,
} from "reactstrap";
import Layout from "./Layout";
import {
  Download,
  Gift,
  Share,
  TrendingUp,
  User,
  UserPlus,
} from "react-feather";
import CountUp from "react-countup";
import { getMethod } from "../api";
import Loader from "../layout/loader/Loader";
import DateRangeFormat from "../utils/DateRangeFormat";
import {
  DASHBOARD_API,
  DASHBOARD_USER_GROWTH_API,
  FINANCE_REPORT,
} from "../api/routes";
import { calculateTotal, decodeData } from "../utils/Helper";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
} from "chart.js";
import { Line } from "react-chartjs-2";
import moment from "moment";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip
);
const chartsData = [];

const Dashboard = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [financialData, setFinancialData] = useState([]);
  const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [monthlyCountData, setMonthlyCountData] = useState([]);
  const token = decodeData(localStorage.getItem("y_g_n_d_a"));

  useEffect(() => {
    (async () => {
      if (!token) return;
      const resUsersChart = await getMethod(
        `${DASHBOARD_USER_GROWTH_API}`,
        token.token
      );
      if (resUsersChart) {
        if (resUsersChart.status === "success") {
          setMonthlyCountData(resUsersChart.data);
        }
      }
    })();
  }, []);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          font: {
            size: 14,
            weight: "bold",
          },
        },
      },
      y: {
        ticks: {
          font: {
            size: 14,
            weight: "bold",
          },
        },
      },
    },
  };

  const labels = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  useEffect(() => {
    labels.map((m) => {
      if (monthlyCountData.length > 0) {
        monthlyCountData.map((month) => {
          if (month.month === m) {
            chartsData.push(month.count);
          }
        });
      }
    });
  }, [monthlyCountData]);

  const chartData = {
    labels,
    datasets: [
      {
        data: chartsData,
        borderColor: "#c1c1c1",
        borderWidth: 1,
        backgroundColor: [
          "#ff6384",
          "#36a2eb",
          "#cc65fe",
          "#ffce56",
          "#ff6384",
          "#36a2eb",
          "#cc65fe",
          "#ffce56",
          "#ff6384",
          "#36a2eb",
          "#cc65fe",
          "#ffce56",
        ],
        pointRadius: 7,
      },
    ],
  };

  useEffect(() => {
    if (token?.user_role === "admin") {
      setLoading(true);
      (async () => {
        if (token) {
          const res = await getMethod(`${DASHBOARD_API}`, token.token);
          res && setLoading(false);
          if (res?.status === "success") {
            setData(res?.data);
          }
        }
      })();
    }
  }, []);

  useEffect(() => {
    if (token?.user_role === "admin") {
      (async () => {
        if (!token) return;
        setLoading(true);
        const res = await getMethod(
          `${FINANCE_REPORT}?start_date=${startDate}&end_date=${endDate}`,
          token.token
        );
        res && setLoading(false);
        if (res?.status === "success") {
          setFinancialData(res.data);
        }
      })();
    }
  }, [startDate, endDate]);

  return (
    <>
      <Layout>
        <Breadcrumb title="Welcome to WBC Dashboard" />
        <Container fluid={true}>
          {loading && <Loader />}
          <Row>
            <Col sm="6" xl="3" lg="6" className="p-1">
              <Card className="o-hidden mb-0">
                <CardBody className="bg-primary text-white b-r-4">
                  <div className="media static-top-widget">
                    <div className="align-self-center text-center">
                      <User />
                    </div>
                    <div className="media-body">
                      <span className="m-0">{"Total User ( s )"}</span>
                      <h4 className="mb-0 counter">
                        <CountUp end={data?.total_users} />
                      </h4>
                      <User className="icon-bg" />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col sm="6" xl="3" lg="6" className="p-1">
              <Card className="o-hidden mb-0">
                <CardBody className="bg-primary text-white b-r-4">
                  <div className="media static-top-widget">
                    <div className="align-self-center text-center">
                      <UserPlus />
                    </div>
                    <div className="media-body">
                      <span className="m-0">{"New User ( s )"}</span>
                      <h4 className="mb-0 counter">
                        <CountUp end={data?.today_users} />
                      </h4>
                      <UserPlus className="icon-bg" />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col sm="6" xl="3" lg="6" className="p-1">
              <Card className="o-hidden mb-0">
                <CardBody className="bg-primary text-white b-r-4">
                  <div className="media static-top-widget">
                    <div className="align-self-center text-center">
                      <Download />
                    </div>
                    <div className="media-body">
                      <span className="m-0">{"Deposit Today"}</span>
                      <h4 className="mb-0 counter">
                        <CountUp end={data?.today_deposit} />
                      </h4>
                      <Download className="icon-bg" />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col sm="6" xl="3" lg="6" className="p-1">
              <Card className="o-hidden mb-0">
                <CardBody className="bg-primary text-white b-r-4">
                  <div className="media static-top-widget">
                    <div className="align-self-center text-center">
                      <Download />
                    </div>
                    <div className="media-body">
                      <span className="m-0">{"New Deposit"}</span>
                      <h4 className="mb-0 counter">
                        <CountUp end={data?.new_deposit} />
                      </h4>
                      <Download className="icon-bg" />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col sm="6" xl="3" lg="6" className="p-1">
              <Card className="o-hidden mb-0">
                <CardBody className="bg-primary text-white b-r-4">
                  <div className="media static-top-widget">
                    <div className="align-self-center text-center">
                      <Share />
                    </div>
                    <div className="media-body">
                      <span className="m-0">{"Withdraw Today"}</span>
                      <h4 className="mb-0 counter">
                        <CountUp end={data?.today_withdraw} />
                      </h4>
                      <Share className="icon-bg" />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col sm="6" xl="3" lg="6" className="p-1">
              <Card className="o-hidden mb-0">
                <CardBody className="bg-primary text-white b-r-4">
                  <div className="media static-top-widget">
                    <div className="align-self-center text-center">
                      <Share />
                    </div>
                    <div className="media-body">
                      <span className="m-0">{"New Withdraw"}</span>
                      <h4 className="mb-0 counter">
                        <CountUp end={data?.new_withdraw} />
                      </h4>
                      <Share className="icon-bg" />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col sm="6" xl="3" lg="6" className="p-1">
              <Card className="o-hidden mb-0">
                <CardBody className="bg-primary text-white b-r-4">
                  <div className="media static-top-widget">
                    <div className="align-self-center text-center">
                      <TrendingUp />
                    </div>
                    <div className="media-body">
                      <span className="m-0">{"Today Profit"}</span>
                      <h4 className="mb-0 counter">
                        <CountUp end={data?.today_profit} />
                      </h4>
                      <TrendingUp className="icon-bg" />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col sm="6" xl="3" lg="6" className="p-1">
              <Card className="o-hidden mb-0">
                <CardBody className="bg-primary text-white b-r-4">
                  <div className="media static-top-widget">
                    <div className="align-self-center text-center">
                      <Gift />
                    </div>
                    <div className="media-body">
                      <span className="m-0">{"Today Bonus"}</span>
                      <h4 className="mb-0 counter">
                        <CountUp end={data?.today_bonus} />
                      </h4>
                      <Gift className="icon-bg" />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Card className="mt-5">
              <CardHeader className="row justify-content-between align-items-end">
                <Col md="3 mb-2">
                  <h5>Financial Report</h5>
                </Col>
                <Col md="4 mb-2">
                  <DateRangeFormat
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                  />
                </Col>
              </CardHeader>
              <CardBody>
                <div className="table-responsive table-scroll position-relative">
                  <Table className="table-border-vertical">
                    <thead>
                      <tr className="bg-dark">
                        <th className="text-center text-white" scope="col">
                          <b>{"Pay Account"}</b>
                        </th>
                        <th className="text-center text-white" scope="col">
                          <b>{"D Count"}</b>
                        </th>
                        <th className="text-center text-white" scope="col">
                          <b>{"W Count"}</b>
                        </th>
                        <th className="text-center text-white" scope="col">
                          <b>{"Deposit"}</b>
                        </th>
                        <th className="text-center text-white" scope="col">
                          <b>{"Withdraw"}</b>
                        </th>
                        <th className="text-center text-white" scope="col">
                          <b>{"Profit & Loss"}</b>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {financialData?.length > 0 &&
                        financialData?.map((el, i) => (
                          <tr key={i}>
                            <td className="text-center">
                              {el.payment_provider_name}
                            </td>
                            <td className="text-center">
                              {el.d_count ? el.d_count : 0}
                            </td>
                            <td className="text-center">
                              {el.w_count ? el.w_count : 0}
                            </td>
                            <td className="text-end">
                              {el.d_amount
                                ? el.d_amount.toLocaleString("en-us")
                                : 0}
                            </td>
                            <td className="text-end">
                              {el.w_amount
                                ? el.w_amount.toLocaleString("en-us")
                                : 0}
                            </td>
                            <td className="text-end">
                              {el.profit
                                ? el.profit.toLocaleString("en-us")
                                : 0}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                    <tfoot>
                      <tr className="bg-dark">
                        <td colSpan={3} className="text-center text-white">
                          Total
                        </td>
                        <td className="text-end text-white">
                          {calculateTotal(financialData, "d_amount")}
                        </td>
                        <td className="text-end text-white">
                          {calculateTotal(financialData, "w_amount")}
                        </td>
                        <td className="text-end text-white">
                          {calculateTotal(financialData, "profit")}
                        </td>
                      </tr>
                    </tfoot>
                  </Table>
                </div>
              </CardBody>
            </Card>
          </Row>
          <Row>
            <Card className="mt-5">
              <CardHeader className="row justify-content-between align-items-end">
                <Col md="3 mb-2">
                  <h5>New User Growth</h5>
                </Col>
              </CardHeader>
              <CardBody>
                <Line options={options} data={chartData} />
              </CardBody>
            </Card>
          </Row>
        </Container>
      </Layout>
    </>
  );
};

export default Dashboard;
